import API from './Api';
import NFT from './NFT/NFT';
import Wallet from './Wallet';

export default class WalletManager {
    rpcEndpoint = null;
    wallet = null;
    accountName = null;
    pureCoins = null;
    tstCoins = null;
    myTroveMates = null;
    mySkins = null;
    allSkins = null;
    nftsMetadata = null;

    constructor() {
        this.rpcEndpoint = 'https://wax.greymass.com';
        this.wallet = new Wallet(this.rpcEndpoint);
    }

    isWaxAccount() {
        this.wallet.isWaxAccount();
    }

    async claimNow() {
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        if (autoLoginDetails) {
            const result = await this.wallet.transactCommand([autoLoginDetails]);
            console.log(result);
            return result;
        }
    }

    async loginWAX() {
        const autoLoginDetails = await this.wallet.checkWAXLogin();
        if (autoLoginDetails) {
            console.log("Already logged in with: " + autoLoginDetails.toString());
        } else {
            const loginDetails = await this.wallet.loginWAX();
            console.log(loginDetails);
        }
    }

    async login() {
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        if (autoLoginDetails) {
            console.log("Already logged in with Anchor: " + autoLoginDetails);
        } else {
            const loginDetails = await this.wallet.loginAnchor();
            console.log(loginDetails);
        }
    }

    logout() {
        console.log("Logout")
        this.wallet.logout();
        this.wallet = null;
        this.pureCoins = null;
        this.tstCoins = null;
        this.accountName = null;
        this.myTroveMates = null;
        this.mySkins = null;
        this.allSkins = null;
        this.nftsMetadata = null;
        window.localStorage.removeItem('waxaccount');
    }

    async isLoggedIn() {
        return await this.wallet.isLoggedIn()
    }

    async claimNFT() {
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        if (autoLoginDetails) {
            const result = await this.wallet.transactCommand([autoLoginDetails]);
            console.log(result);
            return result
        } else {
            return null
        }
    }

    async refreshCoins() {
        return;
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        this.accountName = autoLoginDetails.accountName;
        const api = new API(this.accountName, this.rpcEndpoint);
        this.pureCoins = await api.getMyPure();
        this.tstCoins = await api.getMyTroveStones();
        if (this.pureCoins && this.pureCoins.length > 0) {
        } else {
            this.pureCoins = ["0"]
        }
        if (this.tstCoins && this.tstCoins.length > 0) {
        } else {
            this.tstCoins = ["0"]
        }
    }

    async NFTsAvailableForClaim() {
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        this.accountName = autoLoginDetails.accountName;
        const api = new API(this.accountName, this.rpcEndpoint);
        const tableInfo = await api.getAvailableNFTs2();
        console.log(tableInfo.rows);
        if (tableInfo.rows.length > 0) {
            const availableNFT = tableInfo.rows[0];
            if (availableNFT.account == this.accountName) {
                const isNftsAvailableForClaim = availableNFT.alloted_amount - availableNFT.claimed_amount;
                return isNftsAvailableForClaim
            } else {
                return null;
            }          
        } else {
            console.log("No NFTs to claim");
            return null
        }
    }

    ipfsDataForCategory(category) {        
        for (let index = 0; index < this.nftsMetadata.length; index++) {
            const element = this.nftsMetadata[index];
            if (element.tm_type == category) {
                return element.ipfs_json;
            }
        }
        return null;
    }

    metaDataForNFTType(type) {        
        for (let index = 0; index < this.nftsMetadata.length; index++) {
            const element = this.nftsMetadata[index];
            if (element.tm_type == type) {
                return element;
            }
        }
        return null;
    }

    ipfsDataForSkin(category) {      
        for (let index = 0; index < this.allSkins.length; index++) {
            const element = this.allSkins[index];
            if (element.category == category) {
                return element.ipfs_json;
            }
        }
        return null;
    }

    async getClaimedNFTs() {
        if (this.myTroveMates == null) {
            if (this.accountName == null) {
                const autoLoginDetails = await this.wallet.checkWalletLogin();
                this.accountName = autoLoginDetails.accountName;
            }
            this.myTroveMates = []
            this.mySkins = []
            const api = new API(this.accountName, this.rpcEndpoint);
            const tableInfo = await api.getClaimedNFTs2();
            // TODO Sumit await this.getAllSkins();

            if (this.nftsMetadata == null) {
                const NFTMetadata = await api.getNFTsMetadata2();
                this.nftsMetadata = NFTMetadata.rows;
                // console.log(this.nftsMetadata)
            }
            if (tableInfo.rows.length > 0) {
                for (let index = 0; index < tableInfo.rows.length; index++) {
                    const NFTObj = tableInfo.rows[index];
                    NFTObj["metadata"] = this.metaDataForNFTType(NFTObj.tm_type);
                    this.myTroveMates[index] = NFTObj
                    // console.log(NFTObj)
                    // if (NFTObj.claimed && NFTObj.category_type == "trovemate") {
                    //     NFTObj["ipfs_data"] = this.ipfsDataForCategory(NFTObj.category);
                    //     for (let skinIndex = 0; skinIndex < NFTObj.container.length; skinIndex++) {
                    //         const skinElement =  NFTObj.container[index];
                    //         skinElement["ipfs_data"] = this.ipfsDataForSkin(skinElement.category);
                    //     }
                    //     this.myTroveMates[this.myTroveMates.length] = NFTObj
                    //     break;//TODO Sumit remove

                    // } else if (NFTObj.claimed) {
                    //     NFTObj["ipfs_data"] = this.ipfsDataForSkin(NFTObj.category);
                    //     this.mySkins[this.mySkins.length] = NFTObj
                    // }
                }
            } else {
                console.log("No NFTs available");
            }
        }
        
    }

    async getAllSkins() {
        if (this.allSkins == null) {
            if (this.accountName == null) {
                const autoLoginDetails = await this.wallet.checkWalletLogin();
                this.accountName = autoLoginDetails.accountName;
            }

            this.allSkins = []
            const api = new API(this.accountName, this.rpcEndpoint);
            const tableInfo = await api.getAvailableSkins()

            if (tableInfo.rows.length > 0) {

                for (let index = 0; index < tableInfo.rows.length; index++) {
                    const NFTObj = tableInfo.rows[index];
                    this.allSkins[this.allSkins.length] = NFTObj
                }
            } else {
                console.log("No Skins available");
            }
        }
    }

    async buildTM(troveStonesCount) {
        if (troveStonesCount > 0) {
            const autoLoginDetails = await this.wallet.checkWalletLogin();

            if (autoLoginDetails) {
                var action1 = new Map();
                action1.set('data', { 'from': this.accountName, 'to': 'purpleseed33', 'quantity': (troveStonesCount * 100).toFixed(4) + ' TST', 'memo': '' });
                action1.set('name', 'transfer');
                action1.set('account', 'tsttsttst222');
                action1.set('authorization', autoLoginDetails.authorization);
                var objForAction1 = Object.fromEntries(action1);

                var action2 = new Map();
                action2.set('data', { 'account': this.accountName, 'name': this.accountName });
                action2.set('name', 'claim');
                action2.set('account', "purpleseed33");
                action2.set('authorization', autoLoginDetails.authorization);
                var objForAction2 = Object.fromEntries(action2);

                await this.wallet.transactCommand([objForAction1, objForAction2]);
                console.log("TroveMate built")
                this.myTroveMates = null
                this.mySkins = null
                this.allSkins = null
            } else {
                console.log("Error building TroveMate")
            }
        }
    }

    async claimTroveStones(assetids, refreshCoins) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();

        var action1 = new Map();
        action1.set('data', { 'account': this.accountName, 'assetids': assetids });
        action1.set('name', 'minestone');
        action1.set('account', 'purpleseed33');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        await this.wallet.transactCommand([objForAction1]);
        console.log("TroveStones claimed")
        this.pureCoins = null;
        this.tstCoins = null;
        if (refreshCoins) {
            await this.refreshCoins();
        }
    }

    async buySkin(skin) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();

        var action1 = new Map();
        action1.set('data', { 'from': this.accountName, 'to': 'purpleseed33', 'quantity': (skin.price_in_pure / 10000).toFixed(4) + ' PURE', 'memo': skin.category });
        action1.set('name', 'transfer');
        action1.set('account', 'purepure2222');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        var action2 = new Map();
        action2.set('data', { 'account': this.accountName, 'name': this.accountName });
        action2.set('name', 'claim');
        action2.set('account', "purpleseed33");
        action2.set('authorization', autoLoginDetails.authorization);
        var objForAction2 = Object.fromEntries(action2);

        await this.wallet.transactCommand([objForAction1, objForAction2]);
        console.log("Skin purchased")

        this.allSkins = null;
        this.myTroveMates = null;
        this.mySkins = null;
        this.pureCoins = null;
        this.tstCoins = null;

        await this.getAllSkins()
        await this.getClaimedNFTs();
        await this.refreshCoins();
    }

    async attachSkinToTM(skinIDs, troveMateID, color) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();
        var action1 = new Map();
        action1.set('data', { 'owner': this.accountName, 'parent_asset_id': troveMateID, 'child_assetids': skinIDs });
        action1.set('name', 'compose');
        action1.set('account', 'purpleseed33');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        var objForAction2 = null;
        if (color) {
            var action2 = new Map();
            action2.set('data', { 'owner': this.accountName, 'asset_id': troveMateID, "bgc": color });
            action2.set('name', 'updatebgc');
            action2.set('account', 'purpleseed33');
            action2.set('authorization', autoLoginDetails.authorization);
            objForAction2 = Object.fromEntries(action2);
        }
        if (objForAction2) {
            await this.wallet.transactCommand([objForAction1, objForAction2]);
        } else {
            await this.wallet.transactCommand([objForAction1]);
        }
        
        console.log("TM updated")
        this.myTroveMates = null
        this.mySkins = null
        await this.getClaimedNFTs();
    }

    async removeSkinFromTM(skinIDs, troveMateID, color) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();

        var action1 = new Map();
        action1.set('data', { 'owner': this.accountName, 'parent_asset_id': troveMateID, 'child_assetids': skinIDs });
        action1.set('name', 'decompose');
        action1.set('account', 'purpleseed33');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        var objForAction2 = null;
        if (color) {
            var action2 = new Map();
            action2.set('data', { 'owner': this.accountName, 'asset_id': troveMateID, "bgc": color });
            action2.set('name', 'updatebgc');
            action2.set('account', 'purpleseed33');
            action2.set('authorization', autoLoginDetails.authorization);
            objForAction2 = Object.fromEntries(action2);
        }
        if (objForAction2) {
            await this.wallet.transactCommand([objForAction1, objForAction2]);
        } else {
            await this.wallet.transactCommand([objForAction1]);
        }

        console.log("TM updated")
        this.myTroveMates = null
        this.mySkins = null
        await this.getClaimedNFTs();
    }

    async changeSkinOfTM(oSkinIDs,nSkinIDs, troveMateID, color) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();

        var action1 = new Map();
        action1.set('data', { 'owner': this.accountName, 'parent_asset_id': troveMateID, 'child_assetids': oSkinIDs });
        action1.set('name', 'decompose');
        action1.set('account', 'purpleseed33');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        var action2 = new Map();
        action2.set('data', { 'owner': this.accountName, 'parent_asset_id': troveMateID, 'child_assetids': nSkinIDs });
        action2.set('name', 'compose');
        action2.set('account', 'purpleseed33');
        action2.set('authorization', autoLoginDetails.authorization);
        var objForAction2 = Object.fromEntries(action2);

        var objForAction3 = null;
        if (color) {
            var action3 = new Map();
            action3.set('data', { 'owner': this.accountName, 'asset_id': troveMateID, "bgc": color });
            action3.set('name', 'updatebgc');
            action3.set('account', 'purpleseed33');
            action3.set('authorization', autoLoginDetails.authorization);
            objForAction3 = Object.fromEntries(action3);
        }
        if (objForAction3) {
            await this.wallet.transactCommand([objForAction1, objForAction2, objForAction3]);
        } else {
            await this.wallet.transactCommand([objForAction1, objForAction2]);
        }

        console.log("TM updated")
        this.myTroveMates = null
        this.mySkins = null
        await this.getClaimedNFTs();
    }

    async claimAllTroveStones() {
        var assetIds = []
        for (let index = 0; index < this.myTroveMates.length; index++) {
            const trovemate = this.myTroveMates[index];
            assetIds[assetIds.length] = trovemate.id;
        }
        this.claimTroveStones(assetIds, false);
        await this.refreshCoins();
    }

    async changeBGColorOfTromemate(assetId, color) {
        const autoLoginDetails = await this.wallet.checkWalletLogin();

        var action1 = new Map();
        action1.set('data', { 'owner': this.accountName, 'asset_id': assetId, "bgc": color });
        action1.set('name', 'updatebgc');
        action1.set('account', 'purpleseed33');
        action1.set('authorization', autoLoginDetails.authorization);
        var objForAction1 = Object.fromEntries(action1);

        await this.wallet.transactCommand([objForAction1]);
        console.log("TroveMate background color updated")
        this.myTroveMates = null
        await this.getClaimedNFTs();
    }
}