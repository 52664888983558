import React from "react";
import './TroveMateBuilder.css';
import IncDecCounter from "./IncDecCounter";
import { Container, Row, Col } from 'react-grid';

export default class TroveMateBuilder extends React.Component {

    troveStonesCount = 0;
    constructor(props) {
        super(props)
        this.setTroveStrones = this.setTroveStrones.bind(this);
        this.buildTM = this.buildTM.bind(this);
    }

    renderBuildContainer() {
        return (<div className="TroveMateBuilderInfoBox">
            <h2><font color="#F15946" size="4">Enter number of TroveMates:</font></h2>
            <IncDecCounter setTroveStrones={this.setTroveStrones} />
            <div className="buildButtonBox">
                <div className="TMBBuildButton" onClick={this.buildTM}>Build</div>
                <h1><font color="#F15946" size="4">{this.props.troveStones}</font> TroveStones available</h1>
            </div>
        </div>)
    }

    buildTM() {
        this.props.buildTM(this.troveStonesCount);
    }

    setTroveStrones(num) {
        this.troveStonesCount = num;
    }

    doNothing(e) {
        e.stopPropagation();
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        return <div className="TroveMateBuilderContainer" onClick={this.props.dismissPopup}>
            <div className="TroveMateBuilderRoot" onClick={this.doNothing}>
            <table>
                <tbody>
                    <tr>
                        <td className="TroveMateBuilderHeader">Build</td>
                    </tr>
                    <tr>
                        <td className="TroveMateBuilderSubHeader">You need 100 Trove Stones to build a TroveMate.</td>
                    </tr>
                    <tr>
                        <td>
                            <Container>
                                <Row>
                                    {this.renderBuildContainer()}
                                </Row>
                            </Container>
                        </td>
                    </tr>
                </tbody>
            </table>
                {/* <Container>
                    <Row>
                        <Col>
                            {this.renderBuildInfo()}
                        </Col>
                        <Col>
                            {this.renderBuildContainer()}
                        </Col>
                    </Row>
                </Container> */}
            </div>
        </div>
    }
};