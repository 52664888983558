import React from "react";
import './BuySkin.css';
import { Container, Row, Col } from 'react-grid';
import { Img } from 'react-image'
import Loader from "react-js-loader";

export default class BuySkin extends React.Component {
    skins = null;

    constructor(props) {
        super(props)
        this.skins = this.props.skins;
        this.buySkin = this.buySkin.bind(this);
    }

    buySkin(e) {
        const skin = this.skins[e.target.id]
        this.props.buySkin(skin);
    }

    renderSkinsData() {
        if (this.skins) {
            return this.skins.map((skin, index) => {
                const { price_in_pure, prediction_key, ipfs_json, category } = skin
                return (
                    <Col key={index}>
                        <div className="BuySkinContainer">
                            <Img className="BuyImageClass" id={index}
                                src={'https://ipfs.io/ipfs/' + JSON.parse(ipfs_json).m}
                                loader={<Loader type="bubble-loop" bgColor={"#6DB137"} size={50} />}
                            />
                            <div className="BuySkinPriceLabel">{price_in_pure / 10000.00} PURE</div>
                            <div className="BuySkinButtonClass" id={index} onClick={this.buySkin}>Buy</div>
                        </div>
                    </Col>
                )
            })
        } else {
            console.log("No Skins to buy")
        }
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        return <div className="BuySkinPageContainer" onClick={this.props.dismissPopup}>
            <div className="BuySkinPageRoot" onClick={this.doNothing}>
            <table>
                <tbody>
                    <tr>
                        <td className="BuySkinPageHeader">Get Skins</td>
                    </tr>
                    <tr>
                        <td>
                            <Container>
                                <Row>
                                    {this.renderSkinsData()}
                                </Row>
                            </Container>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    }
};