import React from "react";
import './LoginView.css';

export default class LoginView extends React.Component {

    constructor(props) {
        super(props)
    }

    renderLoginView() {
        return (
                    <tbody>
                    <tr>
                            <td>
                            <h1>CHOOSE YOUR WALLET</h1>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <img className="popopButtonImageWax" onClick={this.props.showWAXLogin}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <img className="popopButtonImageAnchor" onClick={this.props.showAnchorLogin}/>
                            </td>
                        </tr>
                        {/* <tr>
                            <td>
                            <img className="popopButtonImageMetamask" onClick={this.props.showAnchorLogin}/>
                            </td>
                        </tr> */}
                    </tbody>)
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        return <div className="LoginViewContainer" onClick={this.props.dismissLogin} >
            <div className='LoginViewRoot' onClick={this.doNothing}>
                <table className="LoginViewTable">
                        {this.renderLoginView()}
                </table>
            </div>
        </div>
    }
};