import { Api, JsonRpc } from 'eosjs';

export default class TroveAPI {
    rpc = null
    rpcEndpoint = null
    accountName = null
    account = null

    constructor(accountName, rpcEndpoint) {
        this.accountName = accountName
        this.rpcEndpoint = rpcEndpoint
        this.rpc = new JsonRpc(this.rpcEndpoint);
        this.account = "purpleseedio";//"purpleseedio"
    }

    getAccountDetails() {
        return this.rpc.get_account(this.accountName) 
    }

    getAvailableNFTs2() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: this.account,           // Contract that we target
                scope: this.account,          // Account that owns the data
                table: 'tmeligible',          // Table name
                lower_bound: this.accountName,     
                limit: 1,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getAvailableNFTs() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: 'purpleseed33',           // Contract that we target
                scope: 'purpleseed33',          // Account that owns the data
                table: 'eligibility',          // Table name
                lower_bound: this.accountName,     
                limit: 1,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getClaimedNFTs() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: 'purpleseed33',           // Contract that we target
                scope: this.accountName,          // Account that owns the data
                table: 'nfts',          // Table name
                // table_key: 'category',          // Table secondary index
                // lower_bound: 'trovemate',            // Table secondary key value     
                limit: 99,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getClaimedNFTs2() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: this.account,           // Contract that we target
                scope: this.accountName,          // Account that owns the data
                table: 'tmnft',          // Table name
                // table_key: 'category',          // Table secondary index
                // lower_bound: 'trovemate',            // Table secondary key value     
                limit: 99,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getAvailableSkins() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: 'purpleseed33',           // Contract that we target
                scope: 'purpleseed33',          // Account that owns the data
                table: 'skinnft',          // Table name
                // table_key: 'category',          // Table secondary index
                // lower_bound: 'trovemate',            // Table secondary key value     
                limit: 99,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getNFTsMetadata() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: 'purpleseed33',           // Contract that we target
                scope: 'purpleseed33',          // Account that owns the data
                table: 'basenft',          // Table name
                // table_key: 'category',          // Table secondary index
                // lower_bound: 'trovemate',            // Table secondary key value     
                limit: 99,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getNFTsMetadata2() {
        return this.rpc.get_table_rows(
            {
                json: true,                 
                code: this.account,           // Contract that we target
                scope: this.account,          // Account that owns the data
                table: 'tmtypes',          // Table name
                // table_key: 'category',          // Table secondary index
                // lower_bound: 'trovemate',            // Table secondary key value     
                limit: 99,                 
                reverse: false,             
                show_payer: false,          
              }
        ) 
    }

    getMyPure() {
        return this.rpc.get_currency_balance(
            'purepure2222', this.accountName, 'PURE'
        ) 
    }

    getMyTroveStones() {
        return this.rpc.get_currency_balance(
            'tsttsttsttst', this.accountName, 'TST'
        ) 
    }
}