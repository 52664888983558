import React from "react";
import './TroveMateBuilder.css';

export default class IncDecCounter extends React.Component {

    num = 0;
    constructor(props) {
        super(props)
        this.incNum = this.incNum.bind(this);
        this.decNum = this.decNum.bind(this);
    }

    incNum() {
        if (this.num < 1000) {
            this.setNum(Number(this.num) + 1);
        }
    }

    decNum() {
        if (this.num > 0) {
            this.setNum(this.num - 1);
        }
    }

    setNum(num) {
        this.num = num;
        var element = document.getElementById("IncDecInput");
        element.innerHTML=num;
        this.props.setTroveStrones(this.num)
    }

    render() {
        return (<div className="IncDecBox">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <button className="IncDecButton" onClick={this.decNum}>-</button>
                        </td>
                        <td>
                            <div className="IncDecInput" id="IncDecInput">0</div>
                        </td>
                        <td>
                            <button className="IncDecButton" onClick={this.incNum}>+</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        );
    }

}
