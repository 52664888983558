import React from "react";
import './MyProfile.css';

export default class MyProfile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // coins: [
            //     {
            //         title: "TROVE STONE",
            //         id: 1,
            //         count: props.troveStones
            //     },
            //     {
            //         title: "PURE",
            //         id: 2,
            //         count: props.pureCoins
            //     }
            // ],
            accountName: props.accountName,
            logout: props.logout,
            dismiss: props.dismiss
        }
    }

    renderWalletData() {
        return this.state.coins.map((coin, index) => {
            const { id, title, count } = coin
            return (
                <tr key={id}>
                    <td>
                        <div className="profileWalletBox">
                            <div className="ProfilePageTitle">{title}: <font color="#F15946">{count}</font></div>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        return <div className="ProfileContainer" onClick={this.state.dismiss}>
            <div className='ProfilePage' id='profilePage' onClick={this.doNothing}>

                <table className="ProfilePageTable" id='ProfilePageTable'>
                    <tbody>
                        <tr>
                            <td className="ProfilePageHeader">{this.state.accountName}</td>
                        </tr>
                        {/* {this.renderWalletData()} */}
                        <tr>
                            <td>
                                <button className='ProfilePageFooter' onClick={this.state.logout}>Log Out</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
};