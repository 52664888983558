import { act } from "@testing-library/react";
import * as waxjs from "@waxio/waxjs/dist";

import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'

export default class WAXWallet {
  wax = null
  link = null
  session = null
  rpcEndpoint = null
  account = null
  userAccount = null

  constructor(rpcEndpoint) {
    this.rpcEndpoint = rpcEndpoint
    this.account = "purpleseedio"

    var waxAccount = JSON.parse(window.localStorage.getItem('waxaccount'));
    if(waxAccount) {
      const wax = new waxjs.WaxJS({
        rpcEndpoint: this.rpcEndpoint,
        userAccount: waxAccount.account,
        pubKeys: waxAccount.keys
      });
      this.wax = wax;
      this.userAccount = this.wax.login();
    } else {
      const wax = new waxjs.WaxJS({ rpcEndpoint: this.rpcEndpoint, tryAutoLogin: true })
      this.wax = wax
    }
  }

  isWaxAccount() {
    return this.wax.user;
  }

  async isLoggedIn() {
    const isWaxLoginAvailable = await this.wax.isAutoLoginAvailable();
    if (isWaxLoginAvailable || this.wax.user) {
      return true;
    }
    
    if (!this.link) {
      this.createLink()
    }

    const session = await this.link.restoreSession('TroveMate')
    if (session || isWaxLoginAvailable) {
      return true;
    }
    return false;
  }

  async checkWAXLogin() {
    const isAutoLoginAvailable = await this.wax.isAutoLoginAvailable();
    if (isAutoLoginAvailable) {
      return {
        name: "claimtm",
        account: this.account,
        accountName: this.wax.user.account,
        authorization: [{
          actor: this.wax.user.account, permission: 'active'
        }],
        data: { account: this.wax.user.account }
      }
    } else {
      return null;
    }
  }

  async loginWAX() {
    try {
      this.userAccount = await this.wax.login();
    } catch (e) {
      console.log(e.message);
    }
    if(this.wax.user) {
      window.localStorage.setItem('waxaccount', JSON.stringify(this.wax.user));
    }
    window.location.reload(false);
  }

  createLink() {
    if (this.link) return null
    const transport = new AnchorLinkBrowserTransport()
    this.link = new AnchorLink({
      transport,
      chains: [
        {
          chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
          nodeUrl: this.rpcEndpoint,
        }
      ],
    })
  }

  async checkAnchorLogin() {
    if (!this.link) {
      this.createLink()
    }

    const session = await this.link.restoreSession('TroveMate')

    if (session) {
      this.session = session
      const { actor, permission } = session.auth

      return {
        name: "claimtm",
        account: this.account,
        accountName: actor.toString(),
        authorization: [{ actor: actor.toString(), permission: permission.toString() }],
        data: { account: actor.toString() }
      }
    } else {
      return null
    }
  }

  async checkWalletLogin() {
    const isAutoLoginAvailable = await this.wax.isAutoLoginAvailable();
    if (isAutoLoginAvailable && this.wax.user) {
      return {
        name: "claimtm",
        account: this.account,
        accountName: this.wax.user.account,
        authorization: [{
          actor: this.wax.user.account, permission: 'active'
        }],
        data: { account: this.wax.user.account }
      }
    }

    if (!this.link) {
      this.createLink()
    }

    const session = await this.link.restoreSession('TroveMate')

    if (session) {
      this.session = session
      const { actor, permission } = session.auth

      return {
        name: "claimtm",
        account: this.account,
        accountName: actor.toString(),
        authorization: [{ actor: actor.toString(), permission: permission.toString() }],
        data: { account: actor.toString() }
      }
    } else {
      return null
    }
  }

  async loginAnchor() {
    if (!this.link) {
      this.createLink()
    }
    const identity = await this.link.login('TroveMate')
    this.session = identity.session

    const { actor, permission } = this.session.auth
    window.location.reload(false);
  }

  async transactWAX(actions) {
    const result = await this.wax.api.transact({ actions }, { blocksBehind: 3, expireSeconds: 1200 });
    console.log(result);
    return result;
  }

  async reloadAll() {
    await new Promise( res => setTimeout(res, 300) );
    window.location.reload(false);
  }

  async transactAnchor(actions) {
    const result = await this.link.transact({actions});
    console.log(result);
    return result;
    // return this.session.transact({ actions })
  }

  transactCommand(actions) {
    if(this.wax.user != null) {
      return this.transactWAX(actions);
    } else {
      return this.transactAnchor(actions);
    }
  }

  async logout() {
    await this.link.clearSessions('TroveMate')
    this.link = null
    this.wax.user = null; 
    window.localStorage.removeItem('waxaccount');
    this.userAccount = null;
  }
}