import React from "react";
import './TroveMateViewer.css';
import Loader from "react-js-loader";
import { Container, Row, Col } from 'react-grid';
import { TwitterPicker } from 'react-color';
import { Img } from 'react-image'
import TroveMate from "../TroveMate/TroveMate";
import FluidGrid from 'react-fluid-grid'

export default class TroveMateViewer extends React.Component {
    trovemate = null;
    trovemateData = null;
    trovemateBGC = null;
    mySkins = null;
    allSkins = null;

    constructor(props) {
        super(props)
        this.trovemate = props.troveMate
        this.trovemateSkins = this.trovemate.container;
        this.mySkins = this.props.mySkins;
        this.trovemateData = JSON.parse(this.trovemate.mdata);
        this.trovemateBGC = this.trovemateData.bgc;
        this.state = {
            showColorPicker: true,
            showSkinPicker: false
        }
        this.claimTroveStones = this.claimTroveStones.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.mintChanges = this.mintChanges.bind(this);
        this.attachSkin = this.attachSkin.bind(this);
        this.removeSkin = this.removeSkin.bind(this);
        this.changeSkin = this.changeSkin.bind(this);
        this.showColorPicker = this.showColorPicker.bind(this);
        this.showSkinPicker = this.showSkinPicker.bind(this);
        this.changeTMSkin = this.changeTMSkin.bind(this);
        this.removeSkinFromTM = this.removeSkinFromTM.bind(this);
        this.troveMateContainer = React.createRef()
    }

    getTMLife() {
        var datePast = new Date(this.trovemate.created_at);
        var dateNow = new Date();

        var seconds = Math.floor((dateNow - (datePast)) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        if (seconds < 60) {
            return seconds + ' seconds old'
        }
        if (minutes < 60) {
            return minutes + ' minutes old'
        }
        if (hours < 24) {
            return hours + ' hours old'
        }
        if (days == 1) {
            return days + ' day old'
        }
        return days + ' days old'
    }

    claimTroveStones() {
        this.props.claimTroveStones([this.trovemate.id])
    }

    showColorPicker() {
        this.setState({ showColorPicker: true, showSkinPicker: false });
    }

    showSkinPicker() {
        this.setState({ showColorPicker: false, showSkinPicker: true });
    }

    renderTrovemate() {
        const { showColorPicker, showSkinPicker } = this.state;
        const transition = 'top 100ms ease-in-out, left 100ms ease-in-out'
        const columns = 5;
        const styleStrategies = [
            { mediaQuery: '(min-width: 1024px)', style: { numberOfColumns: columns, gutterHeight: 3, gutterWidth: 3 } }
        ]

        return (<div className="TMObjContainer">
            {/* <div style={{left:"35px", position:"relative"}}> */}
            <TroveMate ref={this.troveMateContainer} troveMate={this.trovemate} tmWidth="330px" tmHeight="420px" skinLeft="40px" skinTop="40px" SkinSize="80px" removeSkinFromTM={this.removeSkinFromTM}/>
            {/* </div> */}
            {/* <table className="MenuItemsForEditor" width="400px">
                <tbody>
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td width="200px" style={{ backgroundColor: "rgba(250,0,0,0.5", borderTopLeftRadius: "10px" }}>
                            <h1 onClick={this.showColorPicker}>Background</h1>
                        </td>
                        <td width="200px" style={{ backgroundColor: "rgba(250,0,0,0.5", borderTopRightRadius: "10px" }}>
                            <h1 onClick={this.showSkinPicker}>Skins</h1>
                        </td>
                    </tr>

                </tbody>
            </table> */}
            {/* <table>
                <tbody>
                    <tr>
                        {
                            showColorPicker &&
                            (<td>
                                <TwitterPicker width="392px" triangle="hide" onChange={this.handleColorChange} />
                            </td>)
                        }
                        {
                            showSkinPicker &&
                            (<td>
                                <FluidGrid className="TroveMateViewerSkinContainer"
                                styleStrategies={styleStrategies}
                                >
                                {this.renderMySkinsData()}
                                </FluidGrid>
                            </td>)
                        }

                    </tr>
                </tbody>
            </table> */}
            {/* <div className="EditButton" onClick={this.mintChanges}>
                Save
            </div> */}

        </div>)
    }

    removeSkinFromTM(skin) {
        this.trovemateSkins = [];
        this.troveMateContainer.current.updateTroveMateSkin()
    }

    attachSkin(skin, color) {
        this.props.attachSkinToTM([skin.id], this.trovemate.id, color);
    }

    removeSkin(skin, color) {
        this.props.removeSkinFromTM([skin.id], this.trovemate.id, color);
    }

    changeSkin(pskin, nskin, color) {
        this.props.changeSkinOfTM([pskin.id],[nskin.id], this.trovemate.id, color);
    }

    mintChanges() {
        if (this.trovemate.container.length == 0 && this.trovemateSkins.length == 0) {
            // No Skin change
            if (this.trovemateBGC != this.trovemateData.bgc) {
                this.trovemateData.bgc = this.trovemateBGC;
                this.props.changeBGColorOfTromemate(this.trovemate.id, this.trovemateBGC);
            }
        } else if (this.trovemate.container.length == 0 && this.trovemateSkins.length > 0) {
            // Skin added
            var color = null
            if (this.trovemateBGC != this.trovemateData.bgc) {
                this.trovemateData.bgc = this.trovemateBGC;
                color = this.trovemateData.bgc;
            } 
            this.attachSkin(this.trovemateSkins[0], color);
        } else if (this.trovemate.container.length > 0 && this.trovemateSkins.length == 0) {
            // Skin removed
            var color = null
            if (this.trovemateBGC != this.trovemateData.bgc) {
                this.trovemateData.bgc = this.trovemateBGC;
                color = this.trovemateData.bgc;
            } 
            this.removeSkin(this.trovemate.container[0], color);
        } else if (this.trovemate.container[0].category == this.trovemateSkins[0].category) {
            // No Skin change
            if (this.trovemateBGC != this.trovemateData.bgc) {
                this.trovemateData.bgc = this.trovemateBGC;
                this.props.changeBGColorOfTromemate(this.trovemate.id, this.trovemateBGC);
            }
        } else {
            // Skin updated
            var color = null
            if (this.trovemateBGC != this.trovemateData.bgc) {
                this.trovemateData.bgc = this.trovemateBGC;
                color = this.trovemateData.bgc;
            } 
            this.changeSkin(this.trovemate.container[0],this.trovemateSkins[0], color);
        }
    }

    handleColorChange(color, event) {
        this.trovemateBGC = color.hex;
        this.troveMateContainer.current.updateTroveMateBackgroundColor(color.hex);
    }

    changeTMSkin(e) {
        const skin = this.mySkins[e.target.id]
        this.trovemateSkins = [skin]
        this.troveMateContainer.current.updateTroveMateSkin(skin)
    }

    renderTrovemateSkinsData() {
        if (this.trovemateSkins) {
            return this.trovemateSkins.map((skin, index) => {
                const { id, category } = skin
                const skinData = JSON.parse(skin.mdata);
                return (
                    <Col key={id}>
                        <div className="tmSkinContainer">
                            <Img className="skin" id={id}
                                src={'https://ipfs.io/ipfs/' + skinData.ipfs}
                                loader={<Loader type="bubble-loop" bgColor={"#6DB137"} size={50} />}
                            />
                            <div className="BuySkinButton" id={index} onClick={this.removeSkin}>Remove</div>
                        </div>
                    </Col>
                )
            })
        } else {
            return (<div className="NoSkinsLabel">Add from my skins.</div>)
            console.log("You have no TroveMates")
        }
    }

    renderMySkinsData() {
        if (this.mySkins.length) {
            return this.mySkins.map((skin, index) => {
                const { id, category } = skin
                const skinIPFSData = JSON.parse(skin.ipfs_data);
                return (
                            <Img key={id} className="skin" id={index}
                                src={'https://ipfs.io/ipfs/' + skinIPFSData.m}
                                loader={<Loader type="bubble-loop" bgColor={"#6DB137"} size={30} />}
                                onClick={this.changeTMSkin}/>
                )
            })
        } else {
            return (<div className="NoSkinsLabel">No skins, buy now!</div>)
            console.log("You have no TroveMates")
        }
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        const TrovemateIData = JSON.parse(this.trovemate.idata);
        var firstLetter = TrovemateIData.name.slice(0,1);
        var CName = firstLetter.toUpperCase() + TrovemateIData.name.substring(1);

        return (
            <div id="TroveMateViewerContainer" className="TroveMateViewerContainer" onClick={this.props.dismissViewer} >
                <div className='TroveMateViewerRoot' id="TroveMateViewerRoot" onClick={this.doNothing}>
                    <div className="TroveMateInfoBox">
                        <h1>{CName}</h1>
                        {/* <h2>ID: <font color="#F15946">#{this.trovemate.id}</font></h2> */}
                        <h2>&nbsp;<font color="#F15946">{this.getTMLife()}</font></h2>
                        {/* <div className="ClaimButton" onClick={this.claimTroveStones}>
                            Claim TroveStones
                        </div> */}
                    </div>
                    {/* <img className="backButton" src="back.svg" onClick={this.props.dismissViewer}></img> */}
                    <Container>
                        <Row>
                            <Col>
                                <div>
                                    {this.renderTrovemate()}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>)
    }
};