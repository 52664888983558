import React from "react";
import './MainPage.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaQuestionCircle,  FaWrench, FaWallet, FaCannabis} from 'react-icons/fa';

export default class MainPage extends React.Component {

    constructor(props) {
        super(props)
    }

    renderLeftBar() {
        return (
            <div>
                <ProSidebar>
                    <Menu iconShape="square">
                    <MenuItem icon={<FaCannabis />}>My Collection </MenuItem>
                        <MenuItem icon={<FaWrench />}>Factory</MenuItem>
                        <MenuItem icon={<FaQuestionCircle />}>FAQ</MenuItem>
                        <SubMenu title="Wallet" icon={<FaWallet />}>
                            <MenuItem>Component 1</MenuItem>
                            <MenuItem>Component 2</MenuItem>
                        </SubMenu>
                    </Menu>
                </ProSidebar>
            </div>
        )
    }

    render() {
        return <div className='MainPageBox'>
            {this.renderLeftBar()}
        </div>
    }
};