import React from "react";
import './FAQ.css';

export default class FAQPage extends React.Component {

    constructor(props) {
        super(props)
    }

    renderFAQData() {
        return (
            <div>
                <font color="#13443E" face="Urbanist-SemiBold">
                    <font color="#F15946" face="Urbanist-Bold">
                        X-GAME
                    </font><br /><br />
                    <font face="Urbanist-Bold">
                        What is X-Game?
                    </font><br />
                    X-Game is designed to test your intuitions about the crypto market. You are rewarded with PURE token you if you are correct.
                    <br /><br />
                    <font face="Urbanist-Bold">
                        What do I need to participate?
                    </font><br />
                    You primarily need a TroveMate to participate, besides the more obvious tools such as internet and enthusiasm for blockchain.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        How do I play?
                    </font><br />
                    Every day, you need to ponder and decide which cryptocurrency or token will grow or fall the most on the following day, in terms of its market price. To participate, you will make your TroveMate wear the corresponding skin. You will have to pay a nominal “minting cost” to compose the skin onto your TroveMate.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What is minting cost?
                    </font><br />
                    Minting cost is the cost of altering your TroveMate or composing limited edition skins onto the TroveMate to augment its beauty and participate in X-game. Currently, it is only paid in PURE, the platform’s utility and governance token.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Do minting costs vary?
                    </font><br />
                    For a new user, the cost of composing a BTC skin onto a TroveMate would be the same as the cost of composing an ETH or any other skin – the ‘original cost’. As a user composes different skins, she builds a repository of various skins; the cost of composing these already obtained skins will be 0.25X the original cost.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What does the timer represent?
                    </font><br />
                    Timer represents the upcoming deadline to participate in the following day’s game. If Day 1 starts at 00.00 UTC, participation should be concluded by 23.59 UTC of Day 0. Once Day 1 starts (00.01 UTC), the user has until 23.59 UTC of Day 1 to participate in Day 2’s game, and so on.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        How is the rewarding decision made?
                    </font><br />
                    Top three coins with highest volatility during a 24-hour period are the winners. Volatility is measured by percentage change in market price. For beta-phase, Coinmarketcap data will be used to settle the game and smart contracts will reward users whose TroveMates wear those skins.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Who wins?
                    </font><br />
                    80% of PURE mined every day is distributed to users as ‘prediction pool’. 50% of the prediction pool will be allocated to TroveMate users with skins of most volatile coins, 30% to second-most volatile, and 20% to third-most volatile.
                    So, what factors govern the amount of PURE I win?
                    First and foremost, market intuition. If you know more about how the market behaves, you will probably win more by predicting the outcomes correctly. Second, the number of TroveMates you own. If you have multiple TroveMates, you can make all of them wear different skins and have a higher probability of winning. Third, the number of people in the community making the same prediction. Currently, it is possible that if more users predict the #1 winner correctly, reward per user dilutes and becomes lower than what #2 or #3 winners receive. Soon, we will provide more data on odds, potential rewards, and market insights to enable better decision-making.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What happens if I have multiple TroveMates?
                    </font><br />
                    For now, you can participate in the game through multiple TroveMates. Over time, this functionality will be restricted to make the game more fun.

                    <br /><br /><br />
                    <font face="Urbanist-Bold" color="#F15946">
                        TROVEMATES
                    </font><br />

                    <br />
                    <font face="Urbanist-Bold">
                        What are TroveMates?
                    </font><br />
                    Well, the question should be “who” they are. These cute immortal humanoid droids have faced the atrocities of the fiat world for centuries. Their powers and abilities have been curtailed for long. Finally, they have managed to escape and have entered the blockchain universe. They are looking for friends to guide them and enable them to employ their special powers to make the world a better place.


                    <br /><br />
                    <font face="Urbanist-Bold">
                        Love it! Tell me more about their history.
                    </font><br />
                    Once upon a time... MOTHER BLOCKCHAIN STORY

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Why are they here?
                    </font><br />
                    Some think they are messiahs sent by Mother Blockchain to rectify the wrong. Others believe, they are prisoners of war who have finally escaped and starting a new life. One thing is certain – they are here to expand their community, enabling more people to experience the transparency, decentralization and XX of the blockchain world. Rest, you will discover yourself!

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What special powers do they have?
                    </font><br />
                    Well, too many to count. But topmost are composability, splitability, and fraction mining. And then there are “unknown unknowns” that Purple Seed would gradually help them discover as they settle in this extraordinary world.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Interesting, tell me more about these already discovered powers.
                    </font><br />
                    First, Composability allows TroveMates to not only wear attractive skins (limited edition NFTs), but also morph into different animated characters. Second, TroveMates can be split - like amoeba - into 100 small SEED particles; while the original powers of the TroveMate are lost, this ability gives users the choice to trade them with ease. Third, TroveMates work at a factory 24X7, where they continuously mine new TroveMate fractions (SEED/silica/ quartz); with user’s single click, 100 SEED can be integrated to produce a new TroveMate offspring!
                    How can I befriend a TroveMate?
                    8,000 first-gen TroveMates will be available to befriend on XX date as a drop here. If you have a Telos account and Anchor/XX/XX wallet, you can befriend one with a click. Just like in the real world – there is no cost associated with this friendship. While they can be exchanged on third-party platforms too, we will soon launch a marketplace for secondary trades of TroveMates, limited edition skins and other attractive features.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Why should I own one?
                    </font><br />
                    First and foremost, they are cute, and need your love, attention, and friendship. Second, they are your key to X-Game and to an upcoming suite of crypto games and products that Purple Seed is currently developing. Third, with advanced token economics and Defi capabilities, they help you win and succeed in different ways.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Are all genesis TroveMates identical?
                    </font><br />
                    Yes and no. At launch, 8,000 first-gen TroveMates look identical, though each has a unique name and serial number. Over time, users groom their looks by composing skins, designs and other favorite features, thus making them as unique and non-fungible as one can imagine.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What about new TroveMates?
                    </font><br />
                    With the SEED produced by TroveMates, users will integrate new second-generation TroveMates. They will be slightly different, with 7 letter names and 5-digit serial numbers. Yet, they will continue to possess all special powers of the genesis TroveMates (except splitability?)

                    <br /><br />
                    <font face="Urbanist-Bold">
                        How can I mint Second-Gen TroveMates?
                    </font><br />
                    You don’t have to do much! Genesis TroveMates do that for you. For the next one year, they will work at XX-factory 24X7 to produce SEED. You can monitor their activity in your wallet or factory. You will be able to claim these SEED (under the “claim” section) and integrate them to form a new TroveMate (under the “factory” section).

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What is next?
                    </font><br />
                    Well, a bunch of things. In phase 1, X-game will expand to include more coins, and more types of predictions. We are building designs and various crypto features (powers!) that can be composed onto these droids to secure maximum utility. In phase 2, TroveMate will become a key to various games, crypto investing products and a decentralized social network. Many features are currently being built in stealth mode and cannot be revealed at this moment. We are working under the guidance of Mother Blockchain and will keep you updated on the developments.


                    <br /><br /><br />
                    <font face="Urbanist-Bold" color="#F15946">
                        TOKENS
                    </font><br />


                    <br />
                    <font face="Urbanist-Bold">
                        What is PURE?
                    </font><br />
                    PURE is the utility and governance token of Purple Seed platform and its upcoming suite of products. PURE, for now, will allow TroveMate customizations and participation in prediction games. Soon, it will help users access and use other crypto features and products. PURE also allocates governance and voting rights to owners, enabling Purple Seed to function as a decentralized autonomous organization.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        What is SEED?
                    </font><br />
                    SEED is 1/100th part of a TroveMate. Think of them as cents for a dollar. Like a dollar, a TroveMate can be split into or exchanged for 100 SEED, and like a cent, 100 SEED can be integrated to build a TroveMate.

                    <br /><br />
                    <font face="Urbanist-Bold">
                        Tell me more about Tokenomics!
                    </font><br />
                    We would love to! Please read our whitepaper here X.

                    We love feedback! Please join our Telegram/ Discord/ Twitter communities and let us know how we can improve your friendly experience with TroveMates. Our official hashtag is #TroveMate4Life
                </font></div>
        )
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        return <div className="FAQPageContainer" onClick={this.props.dismissPopup}>
            <div className="FAQPageRoot" onClick={this.doNothing}>
                <div className='faqPage' id='faqPage'>
                    <table className="FAQBox">
                        <tbody>
                            <tr>
                                <td className="FAQPageHeader">FAQs</td>
                            </tr>
                            <tr>
                                <td>
                                    {this.renderFAQData()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div></div>
    }
};