import React from "react";
import './SideMenu.css';
import { ProSidebar, Menu, MenuItem, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import NFTsPage from "../MyTroveMates/NFTsPage";
import WalletManager from "../WalletManager";

export default class SideMenu extends React.Component {

    walletManager = null;
    trovemates = null;
    skins = null;

    constructor(props) {
        super(props)
        this.walletManager = props.walletManager;
        this.trovemates = this.walletManager.myTroveMates;
        this.skins = this.walletManager.mySkins;

        this.state = {
            menuCollapse: (window.innerWidth <= 760),
            showHideNFTs:false
        }
        this.myTrovemates = React.createRef();
        this.mySkins = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({menuCollapse: window.innerWidth <= 760});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    refresh(walletManager) {
        this.walletManager = walletManager;
        this.trovemates = this.walletManager.myTroveMates;
        this.skins = this.walletManager.mySkins;

        this.setState({showHideNFTs: true});
        this.myTrovemates.current.refresh(this.trovemates);
        this.mySkins.current.refresh(this.skins);
    }

    renderBar() {
        const { menuCollapse, showHideNFTs } = this.state;

        return (
            <div className="SideMenuRootBox">
                {/* <ProSidebar collapsed={menuCollapse} width="270px" > */}
                    {/* <Menu iconShape="circle">
                        <MenuItem icon={<FaCannabis />} onClick={this.props.showMyTrovematesPage}>MY COLLECTION </MenuItem>
                        <MenuItem icon={<FaGlasses />} onClick={this.props.showBuySkinPage}>BUY SKINS </MenuItem>
                        <MenuItem icon={<FaHammer />} onClick={this.props.showTroveMateBuilder}>BUILD </MenuItem>
                        <MenuItem icon={<FaCookie />} onClick={this.props.claimAllTroveStones}>CLAIM</MenuItem>
                        <MenuItem icon={<FaQuestionCircle />} onClick={this.props.showFAQPage}>FAQ</MenuItem>
                        <MenuItem icon={<FaWallet />} onClick={this.props.showWalletPage}>WALLET</MenuItem>
                    </Menu> */}
                    {showHideNFTs && <NFTsPage ref={this.myTrovemates} nfts={this.trovemates} showNFT={this.props.showNFT} title="TroveMates" tmWidth="140px" tmHeight="192px" columns="2"/>}
                    <br/><br/>
                    {showHideNFTs && <NFTsPage ref={this.mySkins} nfts={this.skins} title="Skins" tmWidth="84px" tmHeight="84px" columns="3"/>}
                    <br/><br/>
                    <div className="SideMenuBuySkin" onClick={this.props.showBuySkinPage}>Buy Skin</div>
                {/* </ProSidebar> */}
            </div>
        )
    }

    render() {
        return <div className='SideMenuBox'>
            {this.renderBar()}
        </div>
    }
};