import React from "react";
import './Roadmap.css';
import LoginView from "../Login/LoginView";
import * as ReactDOM from 'react-dom'

export default class Roadmap extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showHideLogin: false
        };
        this.showLogin = this.showLogin.bind(this);
        this.hideLogin = this.hideLogin.bind(this);
        this.readyToClick = this.readyToClick.bind(this);
        this.mouseLeft = this.mouseLeft.bind(this);
    }

    showLogin() {
        var audio = new Audio("clicksound2.mp3");
        audio.play();
        // document.getElementById("StartButtonRoadmap").src = "startbutton1.svg";
        setTimeout(() => {  this.setState({ showHideLogin: !this.state.showHideLogin }); }, 300);
        
    }

    hideLogin() {
        this.setState({ showHideLogin: !this.state.showHideLogin });
    }

    readyToClick() {
        document.getElementById("StartButtonRoadmap").src = "startbutton2.svg";
    }

    mouseLeft() {
        document.getElementById("StartButtonRoadmap").src = "startbutton1.svg";

    }

    render() {
        const { showHideLogin } = this.state;

        return <div className='RoadmapContainerBox' id='RoadmapRoot'>
            <div className="RoadmapChildBox">
            <div className="RoadmapTopView">
            <img className="RoadmapImage" src="LandingPage2.svg" />
            {/* <button className="RoadmapLoginButton" onClick={this.showLogin}></button> */}
            <img id="StartButtonRoadmap" className="StartButtonRoadmap" src="startbutton1.svg" onMouseDown={this.showLogin} onMouseEnter={this.readyToClick} onMouseLeave={this.mouseLeft} />

            {/* <button className="RoadmapLoginButton" onClick={this.showLogin}></button> */}
            {showHideLogin && <div className="LoginBoxForRoadmap" id="LoginBoxForRoadmap">
            <LoginView showAnchorLogin={this.props.showAnchorLogin} showWAXLogin={this.props.showWAXLogin} dismissLogin={this.hideLogin} />
                </div>}

            </div>
            <img className="RoadmapImage" src="RoadMap.svg" />
            <div className="RoadmapFooter">

        <a href="https://discord.gg/UDPe9QXhc3" target="_blank" rel="noopener noreferrer"><img className="RoadmapFooterImage" src="Discord.svg"></img></a>
        
        <a href="https://twitter.com/PurpleSeedCo/" target="_blank" rel="noopener noreferrer"><img className="RoadmapFooterImage" src="Twitter.svg" /></a>
        <a href="https://medium.com/@PurpleSeedCo" target="_blank" rel="noopener noreferrer"><img className="RoadmapFooterImage" src="medium.svg" /></a>
        </div>
        <a className="RoadmapFooterPurple" href="https://purpleseed.io/" target="_blank" rel="noopener noreferrer"><img src="PurpleSeed.svg"></img></a>
        <div className="RoadmapHeader">
        <a className="RoadmapHeaderHRef" href="https://docs.trovemate.io/" target="_blank" rel="noopener noreferrer"><b>WHITEPAPER</b></a>
        </div>
            </div>
            



        </div>
    }
};