import React from "react";
import './FactoryPage.css';
import TroveMateBuilder from './../TroveMateBuilder/TroveMateBuilder';

export default class FactoryPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showHideBuilder: false,
            cards: [
                {
                    image: "claimicon.svg",
                    title: "CLAIM"
                },
                {
                    image: "buildicon.svg",
                    title: "BUILD"
                }
            ]
        }

        this.itemClicked = this.itemClicked.bind(this);
        this.showBuilder = this.showBuilder.bind(this);
        this.hideBuilder = this.hideBuilder.bind(this);
    }

    showBuilder(e) {
        this.setState({ showHideBuilder: !this.state.showHideBuilder });
    }
    hideBuilder(e) {
        this.setState({ showHideBuilder: !this.state.showHideBuilder });
    }
    itemClicked(e) {
        if (e.target.id == "BUILD") {
            this.showBuilder();
        } else if (e.target.id == "CLAIM") {
            this.props.claimAllTroveStones();
        }
    }

    renderCards() {
        return this.state.cards.map((card, index) => {
            const { image, title } = card
            return (
                <td key={title} width="50px">
                    <div className="factoryCardContainer" id={title} onClick={this.itemClicked}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={image} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h1 className="factoryCardText">{title}</h1>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </td>
            )
        })
    }

    render() {
        const { showHideBuilder } = this.state;

        return <div>
            <table className="FactoryBox">
                <tbody>
                    <tr>
                        <td className="PageHeader">Factory</td>
                    </tr>
                    <tr>
                        {this.renderCards()}
                    </tr>
                </tbody>
            </table>
            {showHideBuilder && <TroveMateBuilder troveStones={this.props.troveStones} buildTM={this.props.buildTM} dismiss={this.hideBuilder} />}
        </div>
    }
};