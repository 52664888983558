import React from "react";
import './TroveMate.css';
import { Img } from 'react-image'
import Loader from "react-js-loader";
// import { bounce, fadeIn, zoomIn } from 'react-animations';
// import Radium, { StyleRoot } from 'radium';
// import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

// const styles = {
//     bounce: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(bounce, 'bounce')
//     },
//     zoomIn: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(zoomIn, 'zoomIn')
//     }
// }

export default class TroveMate extends React.Component {

    constructor(props) {
        super(props)
        // this.showTroveMate = this.showTroveMate.bind(this);
        // const tmID = Date.now() + "-" + this.trovemate.id;
        // const skinID = Date.now() + "-";
        // this.state = {
        //     tmID: tmID,
        //     skinPrefix: skinID
        // }
        // this.skinDraggedComplete = this.skinDraggedComplete.bind(this);
    }



    // updateSize() {
    //     const troveMateElement = document.getElementById(this.state.tmID);
    //     if (troveMateElement && this.props.tmWidth) {
    //         troveMateElement.style.width = this.props.tmWidth;
    //         troveMateElement.style.height = this.props.tmHwight;
    //     }

    //     for (let index = 0; index < this.trovemateSkins.length; index++) {
    //         const skinObj = this.trovemateSkins[index];
    //         const skinElement = document.getElementById(this.state.skinPrefix + skinObj.id);
    //         if (skinElement && this.skinSize) {
    //             skinElement.style.height = this.skinSize;
    //         }
    //     }
    // }

    // updateTroveMateBackgroundColor(color) {
    //     this.trovemateBGC = color;
    //     this.setState({});
    // }

    // updateTroveMateSkin(skin) {
    //     if (skin) {
    //         this.trovemateSkins = [skin];
    //     } else {
    //         this.trovemateSkins = [];
    //     }
    //     this.setState({});
    // }

    // updateTrovemate(trovemate) {
    //     this.trovemate = trovemate
    //     this.trovemateSkins = this.trovemate.container;
    //     this.trovemateData = JSON.parse(this.trovemate.mdata);
    //     this.trovemateBGC = this.trovemateData.bgc;
    //     this.setState({});
    //     this.updateSize();
    // }

    // showTroveMate() {
    //     if (this.props.showTroveMate) {
    //         this.props.showTroveMate(this.trovemate);
    //     }
    // }

    // skinDraggedComplete(dragData, currentTarget, x, y) {
    //     if (currentTarget.id == this.state.tmID ||
    //         currentTarget.className == "ddcontainer") {
    //     } else {
    //         this.props.removeSkinFromTM(dragData)
    //     }
    // }

    renderTrovemate() {
        // const IPFSData = JSON.parse(this.props.troveMate.metadata.asset_path);
        // var srcOfImg = 'https://ipfs.io/ipfs/' + IPFSData.original;
        var localSrcOfImg = 'trovematesSquare/'+this.props.troveMate.tm_type+'.jpg';
        // console.log(localSrcOfImg);

            return (<Img className="TroveMateImage" src={localSrcOfImg} />);
    }

    // renderSkins() {
    //     if (this.trovemateSkins && this.trovemateSkins.length > 0) {
    //         const skinSize = this.skinSize;
    //         const skinLeft = this.props.skinLeft ? this.props.skinLeft : "1px";
    //         const skinTop = this.props.skinTop ? this.props.skinTop : "1px";
    //         return this.trovemateSkins.map((skin, index) => {
    //             const { id, category } = skin
    //             const skinIPFSData = JSON.parse(skin.ipfs_data);
    //             return (

    //                 <div key={id} className="SkinBox" style={{ left: skinLeft, top: skinTop }}>
    //                     <DragDropContainer onDragEnd={this.skinDraggedComplete} dragData={skin}>
    //                     <Img className="SkinImage" id={this.state.skinPrefix + id}
    //                         src={'https://ipfs.io/ipfs/' + skinIPFSData.m} style={{ height: skinSize }}
    //                     /></DragDropContainer>
    //                 </div>
    //             )
    //         })
    //     }
    // }

    render() {
        const tmWidth = this.props.tmWidth;
        const tmHeight = this.props.tmHeight;
        return <div className='TroveMateBox' style={{ width: tmWidth, height: tmHeight }}>
            {/* <StyleRoot> */}
                {this.renderTrovemate()}
            {/* </StyleRoot> */}
            {/* <StyleRoot>
                {this.renderSkins()}
            </StyleRoot> */}
        </div>
    }
};