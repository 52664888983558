import React from "react";
import './NFTsPage.css';
import { Container, Row, Col } from 'react-grid';
import TroveMate from "../TroveMate/TroveMate";
import FluidGrid from 'react-fluid-grid'

export default class NFTsPage extends React.Component {

    nfts = null;
    tmWidth = null;
    tmHeight = null;
    columns = null;
    title = null;

    constructor(props) {
        super(props)
        this.nfts = props.nfts;
        this.tmWidth = this.props.tmWidth;
        this.tmHeight = this.props.tmHeight;
        this.title = this.props.title;
        this.columns = this.props.columns;
        this.showTroveMate = this.showTroveMate.bind(this);
    }

    refresh(trovemates) {
        this.nfts = trovemates;
        this.setState({});
    }

    showTroveMate(trovemate) {
        this.props.showNFT(trovemate)
    }

    renderNFTsData() {
        if (this.nfts) {
            return this.nfts.map((nft, index) => {
                const { id } = nft
                return (
                    <TroveMate key={id} troveMate={nft} showTroveMate={this.showTroveMate} tmWidth={this.tmWidth} tmHeight={this.tmHeight} SkinSize="40px" />
                )
            })
        } else {
            console.log("You have no NFTs")
        }
    }

    render() {
        const transition = 'top 100ms ease-in-out, left 100ms ease-in-out'
        const columns = Number(this.columns);
        const styleStrategies = [
            { mediaQuery: '(min-width: 1024px)', style: { numberOfColumns: columns, gutterHeight: 10, gutterWidth: 10 } }
        ]

        return <div>
            <div className="NFTTitle">{this.title}</div>
            <FluidGrid itemClassName='NFTsListClass' styleStrategies={styleStrategies} transition={transition}>
                {/* <Row>
                    <Col><div className="NFTTitle">{this.title}</div>
                    </Col>
                </Row> */}
                {/* <Row> */}
                {this.renderNFTsData()}
                {/* </Row> */}
            </FluidGrid>
            {/* <table>
                <tbody>
                    <tr>
                        <td className="PageHeader">{this.title}</td>
                    </tr>
                    <tr>
                        <td>
                            <Container>
                                <Row>
                                    {this.renderNFTsData()}
                                </Row>
                            </Container>
                        </td>
                    </tr>
                    <tr><td><br /></td></tr>
                </tbody>
            </table> */}
        </div>
    }
};