import React from "react";
import './NFT.css';
import { GLTFModel, AmbientLight, DirectionLight, Tick } from 'react-3d-viewer'

export default class NFT extends React.Component {

    rotation = null;

    constructor(props) {
        super(props)
        this.rotation = { x: 0, y: 0, z: 0 };
        this.getRotation = this.getRotation.bind(this);
        this.tick = Tick(() => {
            this.rotation.y += 0.005
        })
    }

    claimNow = async () => {
        var audio = new Audio("clicksound2.mp3");
        audio.play();
        const autoLoginDetails = await this.props.claimNFT();
        console.log(autoLoginDetails)
    }

    getRotation() {
        return this.rotation
    }

    componentWillUnmount() {
        this.tick.animate = false
        this.tick = null
    }


    readyToClick() {
        document.getElementById("NFTClaimButton").src = "ClaimButton2.svg";
    }

    mouseLeft() {
        document.getElementById("NFTClaimButton").src = "ClaimButton1.svg";

    }

    render() {
        return <div className='NFTBox' id='NFTRoot'>
            <div className="NFTClaimRootBox">
                <div>
                    <img className="NFTImage" src="1306.svg" />
                </div>
                <div>
                    <img className="NFTImageSpotlight" src="7394.svg" />
                </div>
            </div>
            <img id="NFTClaimButton" className="NFTClaimButton" src="ClaimButton1.svg" onMouseDown={this.claimNow} onMouseEnter={this.readyToClick} onMouseLeave={this.mouseLeft} ></img>

        </div >
    }
};