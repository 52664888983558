import React from "react";
import './MyTroveMatesPage.css';
import TroveMate from "../TroveMate/TroveMate";
import FluidGrid from 'react-fluid-grid'

export default class MyTrovematesPage extends React.Component {

    troveMates = null;
    mySkins = null;

    constructor(props) {
        super(props)
        this.troveMates = props.trovemates;
        this.mySkins = this.props.mySkins;
        this.showTroveMate = this.showTroveMate.bind(this);
        const groupedSkins = this.mySkins.reduce((skinMemo, { category,category_type, id, mdata, ipfs_data }) => {
            (skinMemo[category] = skinMemo[category] || []).push({ "id": id, "mdata": mdata, "category_type" : category_type, "ipfs_data" : ipfs_data });
            return skinMemo;
        }, {});
        this.mySkins = groupedSkins;

    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    troveMateForId(id) {
        for (let index = 0; index < this.troveMates.length; index++) {
            const element = this.troveMates[index];
            if (element.id == id) {
                return element;
            }
        }
        return null
    }

    refresh(trovemates, mySkins) {
        this.troveMates = trovemates;
        this.mySkins = mySkins;
        const groupedSkins = this.mySkins.reduce((skinMemo, { category,category_type, id, mdata, ipfs_data }) => {
            (skinMemo[category] = skinMemo[category] || []).push({ "id": id, "mdata": mdata, "category_type" : category_type, "ipfs_data" : ipfs_data });
            return skinMemo;
        }, {});
        this.mySkins = groupedSkins;

        this.setState({});
    }

    showTroveMate(trovemate) {
        this.props.showTroveMateEditor(trovemate);
    }

    showSkinInfo() {

    }

    ucfirst(str) {
        var firstLetter = str.slice(0,1);
        return firstLetter.toUpperCase() + str.substring(1);
    }

    renderTrovematesData() {
        if (this.troveMates) {
            var trovematesAndBuild = [];
            trovematesAndBuild.push.apply(trovematesAndBuild, this.troveMates);
            trovematesAndBuild.push({ id: 100 })

            return trovematesAndBuild.map((trovemate, index) => {
                const { id } = trovemate

                if (id != 100) {
                    const TrovemateIData = JSON.parse(trovemate.idata);
                    var firstLetter = TrovemateIData.name.slice(0,1);
                    var CName = firstLetter.toUpperCase() + TrovemateIData.name.substring(1);
                    return (
                        <div key={id} className="TroveMateBack">
                            <TroveMate troveMate={trovemate} showTroveMate={this.showTroveMate} tmWidth="178px" tmHeight="232px" skinLeft="10px" skinTop="10px" />
                            <div className="TroveMateNameLabel">{CName}</div>
                        </div>
                    )
                } else {
                    return (
                        <div key={id}>
                            <div className="TMPBuildCell" style={{ width: "200px", height: "272px" }} 
                                onClick={this.props.showTroveMateBuilder}><br/><br/>Buy<br/>Trovemate</div>
                            <img src="Build.svg" className="TMPBuildCellImage"></img>

                        </div>
                    )
                }
            })
        } else {
            console.log("You have no TroveMates")
        }
    }

    renderSkinsData() {
        if (this.mySkins) {
            const skinCategories = Object.keys(this.mySkins);
            var skinCategoriesAndBuy = [];
            skinCategoriesAndBuy.push.apply(skinCategoriesAndBuy, skinCategories);
            skinCategoriesAndBuy.push("buySkin")

            return skinCategoriesAndBuy.map(category => {
                if (category != "buySkin") {
                    const skinObjects = this.mySkins[category]
                    const { id } = skinObjects[0]
                    const counter = skinObjects.length > 1 ? "+" + skinObjects.length - 1 : 0
                    return (
                        <div key={id} style={{ height: "135px" }}>
                            <div key={id} className="SkinContainerColumn">
                                <TroveMate troveMate={skinObjects[0]} showTroveMate={this.showSkinInfo} tmWidth="110px" tmHeight="110px" />
                                {(counter > 0) && <div className="CounterView">{"+" + counter}</div>}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={200}>
                            <div className="TMPBuyCell" style={{ width: "110px", height: "110px", backgroundColor: "rgba(255,255,255,0.8)" }} 
                                onClick={this.props.showBuySkinPage}>Buy <br />Skin</div>
                        </div>
                    )
                }

            })
        } else {
            console.log("You have no TroveMates")
        }
    }

    render() {
        const transition = 'top 100ms ease-in-out, left 100ms ease-in-out'
        var columns = Math.floor((window.innerWidth * 0.9) / 210);
        var columnSkins = Math.floor((window.innerWidth * 0.9) / 120);
        const styleStrategies = [
            { mediaQuery: '(max-width: 719.9px)', style: { numberOfColumns: columns, gutterHeight: 10, gutterWidth: 10 } },
            { mediaQuery: '(min-width: 720px) and (max-width: 1023.9px)', style: { numberOfColumns: columns, gutterHeight: 10, gutterWidth: 10 } },
            { mediaQuery: '(min-width: 1024px)', style: { numberOfColumns: columns, gutterHeight: 10, gutterWidth: 10 } }
        ]
        const styleStrategiesSkins = [
            { mediaQuery: '(max-width: 719.9px)', style: { numberOfColumns: columnSkins, gutterHeight: 10, gutterWidth: 10 } },
            { mediaQuery: '(min-width: 720px) and (max-width: 1023.9px)', style: { numberOfColumns: columnSkins, gutterHeight: 10, gutterWidth: 10 } },
            { mediaQuery: '(min-width: 1024px)', style: { numberOfColumns: columnSkins, gutterHeight: 10, gutterWidth: 10 } }
        ]

        return <div className="troveMatePageContainer">

            {/* TMs */}
            <div className="MTPPageHeader">TroveMates</div>
            <FluidGrid styleStrategies={styleStrategies} transition={transition}>
                {this.renderTrovematesData()}
            </FluidGrid>

            {/* <div className="TrovematePageBuySkin" onClick={this.props.claimTroveStones}>Claim TroverStones</div> */}

            <br /><br />
            {/* Skins */}
            {/* TODO Sumit remove */}

            {/* <div className="MTPPageHeader">Skins</div>
            <FluidGrid styleStrategies={styleStrategiesSkins} transition={transition}>
                {this.renderSkinsData()}
            </FluidGrid> */}
        </div>
    }
};