import React, { useState } from 'react';
import './App.css';
import NFT from './NFT/NFT';
import MyTrovematesPage from './MyTroveMates/MyTrovematesPage'
import WalletPage from './WalletPage/WalletPage'
import MyProfile from './Profile/MyProfile'
import FAQPage from './FAQ/FAQ';
import CoverPage from './CoverPage/CoverPage';
import FactoryPage from './FactoryPage/FactoryPage';
import * as ReactDOM from 'react-dom'
import WalletManager from './WalletManager';
import MainPage from './MainPage/MainPage';
import SideMenu from './SideMenu/SideMenu';
import BuySkin from './BuySkin/BuySkin';
import TroveMateBuilder from './TroveMateBuilder/TroveMateBuilder';
import TroveMateViewer from './TroveMateViewer/TroveMateViewer';
import TroveMateInfo from './TroveMateInfo/TroveMateInfo'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Roadmap from './Roadmap/Roadmap';

export default class App extends React.Component {
  walletManager = null;

  constructor() {
    super()
    this.state = {
      showHideProfile: false,
      showHideCoverPage: false,
      showHideRoadmap: true,
      GameAvailable: false,
      showHideFooter: false
    }
    this.walletManager = new WalletManager();

    this.showMyTrovematesPage = this.showMyTrovematesPage.bind(this);
    this.showTroveMateInfo = this.showTroveMateInfo.bind(this);
    this.showWalletPage = this.showWalletPage.bind(this);
    this.showFAQPage = this.showFAQPage.bind(this);
    this.showFactoryPage = this.showFactoryPage.bind(this);
    this.NFTClaimed = this.NFTClaimed.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.loginWAX = this.loginWAX.bind(this);
    this.claimNFT = this.claimNFT.bind(this);
    this.showMyProfile = this.showMyProfile.bind(this);
    this.hideMyProfile = this.hideMyProfile.bind(this);
    this.buildTM = this.buildTM.bind(this);
    this.claimTroveStones = this.claimTroveStones.bind(this);
    this.claimAllTroveStones = this.claimAllTroveStones.bind(this);
    this.changeBGColorOfTromemate = this.changeBGColorOfTromemate.bind(this);
    this.buySkin = this.buySkin.bind(this);
    this.attachSkinToTM = this.attachSkinToTM.bind(this);
    this.removeSkinFromTM = this.removeSkinFromTM.bind(this);
    this.changeSkinOfTM = this.changeSkinOfTM.bind(this);
    this.showBuySkinPage = this.showBuySkinPage.bind(this);
    this.showTroveMateEditor = this.showTroveMateEditor.bind(this);
    this.dismissPopup = this.dismissPopup.bind(this);
    this.myTrovematesPage = React.createRef();
    this.leftSection = React.createRef();
    this.showTroveMateBuilder = this.showTroveMateBuilder.bind(this);

    const firebaseConfig = {
      apiKey: "AIzaSyCVBhbEMgLgfGU8i2C5S_Z-4oYSTxXiG_4",
      authDomain: "trovematereact.firebaseapp.com",
      projectId: "trovematereact",
      storageBucket: "trovematereact.appspot.com",
      messagingSenderId: "1072318402447",
      appId: "1:1072318402447:web:79270d270a60c61e2e05fa",
      measurementId: "G-11PLXY49ME"
    };
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }

  async login() {
    await this.walletManager.login();
  }

  async loginWAX() {
    await this.walletManager.loginWAX();
  }

  logout() {
    console.log("Logout")
    this.walletManager.logout();
    window.location.reload(false);
    this.showCoverPage();
  }

  async refreshCoins() {
    return;//TODO
    await this.walletManager.refreshCoins();
  }

  componentDidMount() {
    this.CheckLoggedIn()
  }

  async CheckLoggedIn() {
    const loggedIn = await this.walletManager.isLoggedIn()
    if (loggedIn) {
      this.showNFTsToClaimIfAvailable()
      this.walletManager.refreshCoins();
    } else {
      this.showCoverPage()
    }
  }

  async showNFTsToClaimIfAvailable() {
    const nftsAvailableForClaim = await this.walletManager.NFTsAvailableForClaim()
    if (nftsAvailableForClaim != null && nftsAvailableForClaim > 0) {
      this.showNFT();
    } else {
      this.NFTClaimed()
    }
  }

  async showTroveMateBuilder() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PopUpContainer'))
    if (this.walletManager.accountName == null || this.walletManager.pureCoins == null || this.walletManager.tstCoins == null) {
      await this.refreshCoins();
    }
    if (this.walletManager.pureCoins.length > 0 && this.walletManager.tstCoins.length > 0) {
      const tsCoinInfo = this.walletManager.tstCoins[0].split(" ")
      ReactDOM.render(<TroveMateBuilder troveStones={Math.round(tsCoinInfo[0])} buildTM={this.buildTM} dismissPopup={this.dismissPopup}/>, document.getElementById('PopUpContainer'))
    }
  }

  async changeBGColorOfTromemate(assetId, color) {
    await this.walletManager.changeBGColorOfTromemate(assetId, color);
    this.showMyTrovematesPage();
  }

  async buildTM(troveStonesCount) {
    await this.walletManager.buildTM(troveStonesCount);
    this.showMyTrovematesPage();
  }

  async claimTroveStones(assetids) {
    await this.walletManager.claimAllTroveStones(assetids);
  }

  async claimAllTroveStones() {
    await this.walletManager.claimAllTroveStones();
  }

  async buySkin(skin) {
    await this.walletManager.buySkin(skin);
    this.myTrovematesPage.current.refresh(this.walletManager.myTroveMates, this.walletManager.mySkins)
  }

  async attachSkinToTM(skinIDs, troveMateID, color) {
    await this.walletManager.attachSkinToTM(skinIDs, troveMateID, color);
    this.dismissPopup();
    // this.myTrovematesPage.current.refresh(this.walletManager.myTroveMates, this.walletManager.mySkins)
    this.showMyTrovematesPage();
  }

  async removeSkinFromTM(skinIDs, troveMateID,color) {
    await this.walletManager.removeSkinFromTM(skinIDs, troveMateID, color);
    this.dismissPopup();
    // this.myTrovematesPage.current.refresh(this.walletManager.myTroveMates, this.walletManager.mySkins)
    this.showMyTrovematesPage();
  }

  async changeSkinOfTM(oSkinIDs, nSkinIDs, troveMateID,color) {
    await this.walletManager.changeSkinOfTM(oSkinIDs, nSkinIDs, troveMateID, color);
    this.dismissPopup();
    // this.myTrovematesPage.current.refresh(this.walletManager.myTroveMates, this.walletManager.mySkins)
    this.showMyTrovematesPage();
  }

  showCoverPage() {
    this.setState({ showHideCoverPage: !this.state.showHideProfile });
  }

  hideAllPages() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
  }

  showMenuBar() {
    this.hideComponent("showHideMenuBar")
  }

  showNFT() {
    ReactDOM.render(<NFT claimNFT={this.claimNFT} NFTClaimed={this.NFTClaimed} />, document.getElementById('PageContainer'))
  }

  async claimNFT() {
    const result = await this.walletManager.claimNFT();
    console.log("Claim complete");
      await new Promise( res => setTimeout(res, 300) );
    // if (this.walletManager.isWaxAccount()) {
    //   console.log("Claim complete");
    //   await new Promise( res => setTimeout(res, 300) );
    //   // window.location.reload(false);
    // }
    // await new Promise( res => setTimeout(res, 300) );
    window.location.reload(false);
    // if (result != null) {
    //   this.NFTClaimed()
    // }
  }

  async showMyTrovematesPage() {
    if (this.state.GameAvailable) {
      return;
    }
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
    await this.walletManager.getClaimedNFTs()
    ReactDOM.render(<MyTrovematesPage ref={this.myTrovematesPage} showTroveMateBuilder={this.showTroveMateBuilder} showTroveMateEditor={this.showTroveMateEditor} showBuySkinPage={this.showBuySkinPage} trovemates={this.walletManager.myTroveMates} claimTroveStones={this.claimTroveStones} mySkins={this.walletManager.mySkins} />, document.getElementById('PageContainer'))
  }

  dismissPopup() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PopUpContainer'))
  }

  showTroveMateEditor(trovemate) {
    ReactDOM.unmountComponentAtNode(document.getElementById('PopUpContainer'))

    ReactDOM.render(<TroveMateViewer
      // ref={this.troveMateViewer} 
      troveMate={trovemate}
      dismissViewer={this.dismissPopup}
      claimTroveStones={this.claimTroveStones}
      mySkins={this.walletManager.mySkins}
      changeBGColorOfTromemate={this.changeBGColorOfTromemate}
      attachSkinToTM={this.attachSkinToTM}
      removeSkinFromTM={this.removeSkinFromTM} 
      changeSkinOfTM={this.changeSkinOfTM}/>, document.getElementById('PopUpContainer'))

  }

  showTroveMateInfo(trovemates) {
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
    ReactDOM.render(<TroveMateInfo trovemates={trovemates}/>, document.getElementById('PageContainer'))

  }

  showFAQPage() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PopUpContainer'))
    ReactDOM.render(<FAQPage dismissPopup={this.dismissPopup}/>, document.getElementById('PopUpContainer'))
  }

  async showBuySkinPage() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PopUpContainer'))
    await this.walletManager.getAllSkins()
    ReactDOM.render(<BuySkin skins={this.walletManager.allSkins} buySkin={this.buySkin} dismissPopup={this.dismissPopup} />, document.getElementById('PopUpContainer'))
  }

  async showFactoryPage() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
    if (this.walletManager.accountName == null || this.walletManager.pureCoins == null || this.walletManager.tstCoins == null) {
      await this.refreshCoins();
    }
    if (this.walletManager.pureCoins.length > 0 && this.walletManager.tstCoins.length > 0) {
      const pureCoinInfo = this.walletManager.pureCoins[0].split(" ")
      const tsCoinInfo = this.walletManager.tstCoins[0].split(" ")
      ReactDOM.render(<FactoryPage buildTM={this.buildTM} troveStones={Math.round(tsCoinInfo[0])} pureCoins={Math.round(pureCoinInfo[0])} claimAllTroveStones={this.claimAllTroveStones} />, document.getElementById('PageContainer'))
    }
  }

  async showWalletPage() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
    if (this.walletManager.accountName == null || this.walletManager.pureCoins == null || this.walletManager.tstCoins == null) {
      await this.refreshCoins();
    }
    if (this.walletManager.pureCoins.length > 0 && this.walletManager.tstCoins.length > 0) {
      const pureCoinInfo = this.walletManager.pureCoins[0].split(" ")
      const tsCoinInfo = this.walletManager.tstCoins[0].split(" ")
      ReactDOM.render(<WalletPage troveStones={Math.round(tsCoinInfo[0])} pureCoins={Math.round(pureCoinInfo[0])} />, document.getElementById('PageContainer'))
    }
  }

  async showMyProfile() {
    // if (this.walletManager.accountName == null || this.walletManager.pureCoins == null || this.walletManager.tstCoins == null) {
    //   await this.refreshCoins();
    // }
    this.setState({ showHideProfile: !this.state.showHideProfile });
  }

  hideMyProfile() {
    this.setState({ showHideProfile: !this.state.showHideProfile });
  }

  async NFTClaimed() {
    // this.hideAllPages()
    await this.walletManager.getClaimedNFTs()

    this.setState({ showHideFooter: true });

    this.showTroveMateInfo(this.walletManager.myTroveMates);
    // this.showMyTrovematesPage();
    if (this.state.GameAvailable) {
      this.leftSection.current.refresh(this.walletManager)
    }
  }

  refresh() {
    window.location.reload(false);
    this.getClaimedNFTs()
  }

  removeNFT() {
    ReactDOM.unmountComponentAtNode(document.getElementById('PageContainer'))
  }

  render() {
    var pureCoinInfo = null;
    var tsCoinInfo = null;
    if (this.walletManager.pureCoins && this.walletManager.tstCoins && this.walletManager.pureCoins.length > 0 && this.walletManager.tstCoins.length > 0) {
      pureCoinInfo = this.walletManager.pureCoins[0].split(" ")
      tsCoinInfo = this.walletManager.tstCoins[0].split(" ")
    }
    const { showHideProfile, showHideCoverPage, GameAvailable, showHideRoadmap, showHideFooter } = this.state;

    // if (showHideRoadmap) {
    //   return <Roadmap showAnchorLogin={this.login} showWAXLogin={this.loginWAX}/>;
    // } 
    return <div className="App" id='MainApp'>
      <div className='AppBackground' />
      <div id="PopUpContainer"></div>

      {/* Header */}
      <header className='topBar' id='topBar'>
        <img className='App-logo' src="trovematelogo1x.png"
          srcSet='trovematelogo1x.png,
                                        trovematelogo2x.png 2x'/>

          <a className="accountInfo" href="#" onClick={this.showMyProfile}>

            <img src="account1x.png"
              srcSet='account1x.png,
                                   account2x.png 2x'/>
          </a>
          <a className="TopMenuItemWhitepaper" href="https://docs.trovemate.io/" target="_blank" rel="noopener noreferrer"><b>WHITEPAPER</b></a>

          {/* <a className="TopMenuItemFAQ" href="#" onClick={this.showFAQPage}>
          FAQ
        </a> */}

        {/* <a className="TopMenuItemCLAIM" href="#" onClick={this.claimAllTroveStones}>
          CLAIM
        </a> */}

      </header>

      
      <div className='AppBody'>
        {GameAvailable && <SideMenu ref={this.leftSection}
          showMyTrovematesPage={this.showMyTrovematesPage}
          showBuySkinPage={this.showBuySkinPage}
          showTroveMateBuilder={this.showTroveMateBuilder}
          showWalletPage={this.showWalletPage}
          showFAQPage={this.showFAQPage}
          showFactoryPage={this.showFactoryPage}
          showNFT={this.showTroveMateEditor}
          claimAllTroveStones={this.claimAllTroveStones}
          walletManager={this.walletManager}></SideMenu>}
        <br></br>
        <div className='AppContainer' id="PageContainer" style={{ width: GameAvailable ? "70%" : "90%", backgroundColor: GameAvailable ? "rgba(0,0,0,0)" : "rgba(0,0,0,0.0)" }}>
          {showHideProfile && <MyProfile accountName={this.walletManager.accountName} logout={this.logout} dismiss={this.hideMyProfile} />}
          {showHideCoverPage && <Roadmap showAnchorLogin={this.login} showWAXLogin={this.loginWAX}/>}
        </div>
      </div>
      {/* Menu */}
      {/* <div className='MenuBar' id="MenuBarContainer"></div> */}


      {/* <div className='AppFooter' /> */}
      { /* Footer*/}
      { showHideFooter &&
      <div className="AppMainFooter">
        <a href="https://discord.gg/UDPe9QXhc3" target="_blank" rel="noopener noreferrer"><img className="AppMainFooterImage" src="Discord.svg"></img></a>
        
        <a href="https://twitter.com/PurpleSeedCo/" target="_blank" rel="noopener noreferrer"><img className="AppMainFooterImage" src="Twitter.svg" /></a>
        <a href="https://medium.com/@PurpleSeedCo" target="_blank" rel="noopener noreferrer"><img className="AppMainFooterImage" src="medium.svg" /></a>
        </div>
  }

<img className="AppRingsBack" src='Rings.svg'></img>
    </div>
  }
}

