import React from "react";
import './TroveMateInfo.css';
import TroveMate from "../TroveMate/TroveMate";
import * as ReactDOM from 'react-dom'

export default class TroveMateInfo extends React.Component {
    trovemates = null;
    trovemate = null;
    currentIndex = 0;
    showNoTrovemateText = false;

    constructor(props) {
        super(props)
        this.trovemates = props.trovemates
        if(this.trovemates.length > 0) {
            this.trovemate = this.trovemates[this.currentIndex]
            this.showLast = this.showLast.bind(this);
            this.showNext = this.showNext.bind(this);
        } else {
            this.showNoTrovemateText = true;
        }
        

    }

    async showLast() {
        if (this.currentIndex > 0) {
            this.currentIndex = this.currentIndex - 1;
        } else {
            this.currentIndex = this.trovemates.length - 1;
        }
        this.trovemate = this.trovemates[this.currentIndex];
        this.setState({ state: this.state });
        await new Promise( res => setTimeout(res, 100) );
        this.setState({ state: this.state });
    }

    async showNext() {
        if (this.currentIndex < this.trovemates.length - 1) {
            this.currentIndex = this.currentIndex + 1;
        } else {
            this.currentIndex = 0;
        }
        this.trovemate = this.trovemates[this.currentIndex];
        this.setState({ state: this.state });
        await new Promise( res => setTimeout(res, 100) );
        this.setState({ state: this.state });
    }

    renderTrovemate() {
        return (<div className="TMObjInfoContainer">
            <div className="TMObjInfoHeaderBox">{this.trovemate.metadata.display_name}#{this.trovemate.tm_type_mint_id}</div>
            <TroveMate troveMate={this.trovemate} tmWidth="500px" tmHeight="500px" />
            <img className="TMObjInfoStarBox" src='star.svg'></img>
        </div>)
    }

    renderTrovemateInformation() {
        return (<div className="TroveMateInformationRoot">
            <div>
                <span className="TroveMateInformationRootTitle">{this.trovemate.metadata.display_name.substring(0, 3)}</span>
                <span className="TroveMateInfoRootTitlePart">{this.trovemate.metadata.display_name.substring(3)}</span>
            </div>
            <div className="TroveMateInfoRootSubTitle">
                {this.trovemate.metadata.description}
            </div>
            <br />
            <br />
            <br />
            <div className="TroveMateInfoRootTileID">
                ID: {this.trovemate.metadata.display_name}#{this.trovemate.tm_type_mint_id}
            </div>
            <div className="TroveMateInfoRootTile">
                SUPPLY: {this.trovemate.metadata.end_range - this.trovemate.metadata.start_range + 1}
            </div>
            <div className="TroveMateInfoRootTile">
                RARITY: {this.trovemate.metadata.rarity}
            </div>
            <div className="TroveMateInfoRootTile">
                GENERATION: {this.trovemate.gen}
            </div>
            <div className="TroveMateInfoRootTile">
                MINTING CHANCE: {this.trovemate.metadata.chance}%
            </div>
        </div>)
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        if(this.showNoTrovemateText) {
            return (
                <div className="NoTrovemateInfo">
                    No trovemates available.
                </div>
            );
        } else {
            return (
                <div id="TroveMateInfoContainer" className="TroveMateInfoContainer" onClick={this.props.dismissViewer} >
                   
                    <div className='TroveMateInfoRoot' id="TroveMateInfoRoot" onClick={this.doNothing}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            {this.renderTrovemate()}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {this.renderTrovemateInformation()}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="TroveMateInfoRootLeftArrow" onClick={this.showLast}>
                        <img className="TrovemateInfoRootLeftArrowImage" src='leftarrow.svg'></img>
                    </div>
                    <div className="TroveMateInfoRootRightArrow" onClick={this.showNext}>
                        <img className="TrovemateInfoRootRightArrowImage" src='rightarrow.svg'></img>
                    </div>
                </div>)
        }
        }
        
};