import React from "react";
import './CoverPage.css';
import LoginView from "../Login/LoginView";

export default class CoverPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showHideLogin: false
        };
        this.showLogin = this.showLogin.bind(this);
        this.hideLogin = this.hideLogin.bind(this);
    }

    showLogin() {
        this.setState({ showHideLogin: !this.state.showHideLogin });
    }

    hideLogin() {
        this.setState({ showHideLogin: !this.state.showHideLogin });
    }

    render() {

        const { showHideLogin } = this.state;

        return <div className='CoverPage' id='coverPage'>
            {/* <img src="coverpage1.svg" /> */}
            <table className="CoverPageContent">
                <tbody>
                    {/* <tr>
                        <td align="center">
                        <div className="CoverTitleText">YOUR KEY TO THE ECOSYSTEM</div><br/>
                        </td>
                    </tr> */}
                    <tr>
                        <td align="center">
                        <button className="loginButton" onClick={this.showLogin}></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            {showHideLogin && <LoginView showAnchorLogin={this.props.showAnchorLogin} showWAXLogin={this.props.showWAXLogin} dismissLogin={this.hideLogin} />}
        
        <div className="CoverPageFooter">
        <a href="https://discord.com/" target="_blank" rel="noopener noreferrer"><img className="CoverPageFooterImage" src="Discord.svg"></img></a>
        
        <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer"><img className="CoverPageFooterImage" src="Twitter.svg" /></a>
        <a href="https://www.gmail.com/" target="_blank" rel="noopener noreferrer"><img className="CoverPageFooterImage" src="Gmail.svg" /></a>
        </div>
        <div className="CoverPageHeader">
        <a className="CoverPageHeaderHRef" href="https://docs.trovemate.io/" target="_blank" rel="noopener noreferrer"><b>WHITEPAPER</b></a>
        </div>
        </div>
    }
};