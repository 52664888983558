import React from "react";
import './WalletPage.css';
import { Container, Row, Col } from 'react-grid';

export default class WalletPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            coins: [
                {
                    title: "TROVE STONE",
                    id: 1,
                    count: props.troveStones
                },
                {
                    title: "PURE",
                    id: 2,
                    count: props.pureCoins
                }
            ]
        }
    }

    renderWalletData() {
        return this.state.coins.map((coin, index) => {
            const { id, title, count } = coin
            return (
                <Col key={id}>
                    <div className="walletContainer">
                        <div className="walletTitle">{count}</div>
                        <div className="walletSubTitle">{title}</div>
                    </div>
                </Col>

            )
        })
    }

    render() {
        return <div>

            <table className="WalletBox">
                <tbody>
                    <tr>
                        <td className="PageHeader">Wallet</td>
                    </tr>
                    <tr>
                        <td>
                        <Container>
                            <Row>
                            {this.renderWalletData()}
                            </Row>
                        </Container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
};